/* Leadership Insights Component */
.leadership-insights {
    background-color: #ffffff;
    color: #ffffff;
    /* padding-left: 35px; */
    font-family: "Montserrat", sans-serif;
    align-items: center;
    display: flex;
    justify-content: center;
}

.header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}

.main-title {
    font-size: 28px;
    max-width: 800px;
    margin: 0;
    font-weight: 600;
}

.read-more-btn {
    background-color: #1b1830;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
}

.cards-container {
    width: 100%;
    max-width: 1400px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    align-items: center;
}

.insight-card {
    flex: 1;
    min-width: 220px;
    height: 250px;
    border-radius: 16px;
    padding: 24px;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Color variations */
.insight-card[data-color="blue-60"] {
    background-color: #99d7ec;
}

.insight-card[data-color="blue-40"] {
    background-color: #66c1e3;
}

.insight-card[data-color="blue-20"] {
    background-color: #33abd0;
}

.insight-card[data-color="blue"] {
    background-color: #0095d9;
}

.card-number {
    font-size: 18px;
    font-weight: bold;
    opacity: 0.8;
}

.executive-name {
    font-size: 26px;
    margin: 0;
    margin-top: auto;
    font-weight: 700;
    align-self: center;
}

.executive-title {
    font-size: 18px;
    margin: 5px 0 0 0;
    opacity: 0.7;
}

/* Hover content */
.hover-content {
    position: absolute;
    bottom: -200px;
    left: 0;
    width: 100%;
    padding: 24px;
    background-color: inherit;
    transition: bottom 0.3s ease;
    box-sizing: border-box;
}

.insight-card:hover .hover-content {
    bottom: 0;
}

.insight-card:hover .executive-name,
.insight-card:hover .executive-title {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.insight-question {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
}

.read-article-btn {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    border: none;
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s ease;
}

.read-article-btn:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .header-container {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }

    .cards-container {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 15px;
        padding: 0 10px;
        justify-content: center;
    }

    .insight-card {
        min-width: calc(50% - 15px);
        max-width: 100%;
        height: 280px;
        padding: 18px;
        margin-bottom: 10px;
    }

    .card-number {
        font-size: 16px;
    }

    .executive-name {
        font-size: 22px;
    }

    .executive-title {
        font-size: 16px;
    }

    .hover-content {
        padding: 18px;
    }

    .insight-question {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .read-article-btn {
        padding: 12px;
        font-size: 14px;
    }

    /* Touch-friendly hover alternative */
    .insight-card:active .hover-content {
        bottom: 0;
    }
}

/* Additional breakpoint for smaller phones */
@media (max-width: 480px) {
    .leadership-insights {
        padding-left: 15px;
        padding-right: 15px;
    }

    .cards-container {
        gap: 10px;
    }

    .insight-card {
        min-width: 100%;
        height: 250px;
    }
}

/* Color variations with 20% intervals */
/* Blue variations */
.insight-card[data-color="blue-20"] {
    background-color: #33abd0; /* 20% lighter */
}
.insight-card[data-color="blue-40"] {
    background-color: #66c1e3; /* 40% lighter */
}
.insight-card[data-color="blue-60"] {
    background-color: #99d7ec; /* 60% lighter */
}
.insight-card[data-color="blue-80"] {
    background-color: #cceef6; /* 80% lighter */
}
.insight-card[data-color="blue-m20"] {
    background-color: #0077ad; /* 20% darker */
}
.insight-card[data-color="blue-m40"] {
    background-color: #005982; /* 40% darker */
}

/* Green variations */
.insight-card[data-color="green-20"] {
    background-color: #7dc983; /* 20% lighter */
}
.insight-card[data-color="green-40"] {
    background-color: #9dd7a3; /* 40% lighter */
}
.insight-card[data-color="green-60"] {
    background-color: #bee5c2; /* 60% lighter */
}
.insight-card[data-color="green-80"] {
    background-color: #def2e0; /* 80% lighter */
}
.insight-card[data-color="green-m20"] {
    background-color: #4a9650; /* 20% darker */
}
.insight-card[data-color="green-m40"] {
    background-color: #38713c; /* 40% darker */
}
